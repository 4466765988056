export function jsonGet(url) {
    return fetch(url, {
        method: "get",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
        .catch((error) => Promise.reject(error.message))
        .then((response) => {
            if (response.status === 204) {
                return null;
            }
            return response.json().then((json) => {
                if (![200, 201].includes(response.status)) {
                    return Promise.reject(json.error);
                }
                return json;
            });
        });
}

export function jsonPost(url, body) {
    return fetch(url, {
        method: "post",
        body: JSON.stringify(body),
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
        .catch((error) => Promise.reject(error.message))
        .then((response) => {
            if (response.status === 204) {
                return null;
            }
            return response.json().then((json) => {
                if (![200, 201, 202].includes(response.status)) {
                    return Promise.reject(json.error);
                }
                return json;
            });
        });
}

export function getJSDate(javaDate) {
    return new Date(javaDate);
}

export function getDateTimeFromJavaDate(javaDate) {
    var jsDate = getJSDate(javaDate);
    var month = jsDate.getMonth() + 1;
    return jsDate.getDate() + "." + month + "." + jsDate.getFullYear() + " " + jsDate.getHours() + ":" + jsDate.getMinutes();
}

export function getDateFromJavaDate(javaDate) {
    var jsDate = getJSDate(javaDate);
    var month = jsDate.getMonth() + 1;
    return jsDate.getDate() + "." + month + "." + jsDate.getFullYear();
}

export function isEven(value) {
	if (value%2 === 0)
		return true;
	else
		return false;
}