import React, { useState, useEffect } from 'react';
import { serverHost } from "../src/config";
import * as Utils from "../src/utils";

import Document from "./Document";
import DocumentFilterSelector from "./DocumentFilterSelector";


function DocumentsList(props) {
    useEffect(() => {
        setAllDocuments([]);
    }, []);

    const [allDocuments, setAllDocuments] = useState([]);

    const [selectedFilter, setSelectedFilter] = useState('sign_date_asc');

    const updateList = () => {
        if (props.userMail != null) {
            let params = new URLSearchParams({
                orderBy: selectedFilter
            });

            let url = serverHost + "/documents/getDocumentsByUserMail?" + params;
            Utils.jsonGet(url)
                .then((json) =>
                    setAllDocuments(json.documents)
                )
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setAllDocuments([])
        }
    }

    useEffect(() => {
        updateList();
    },[selectedFilter])

    const docList = (
        <div>
            <div className = "mt-3 mb-1">
                Сортировать по: 
                <DocumentFilterSelector 
                    selectedFilter = {selectedFilter}
                    setSelectedFilter = {setSelectedFilter}
                />
            </div>
            <div className="container table mt-3 p-0">               
                <div className = "row border-bottom p-1 d-none d-lg-flex d-xl-flex">
                    <div className = "col-sm">
                        <b>Дата добавления</b>
                    </div>
                    <div className = "col-sm">
                        <b>Дата документа</b>
                    </div>
                    <div className = "col-sm">
                        <b>Название</b>
                    </div>
                    <div className = "col-sm">
                        <b>Номер</b>
                    </div>
                    <div className = "col-sm">
                        <b>Подписан</b>
                    </div>
                    <div className = "col-sm">
                        <b>Открыть</b>
                    </div>
                </div>
                {allDocuments.map((item, i) => (
                    <Document
                        key={item.documentId}
                        document={item}
                        userMail={props.userMail}
                        orderNumber={i}
                    />
                ))}
            </div>
        </div>
    );
    return (docList);
}

export default DocumentsList;