import React, { useState, useEffect } from 'react';
import * as Utils from "../src/utils";
import { serverHost } from "../src/config";

function Document(props) {

    useEffect(() => {
        var signedDate = null;
        props.document.userDocuments.forEach(userDoc => {
            userDoc.user.userEmails.forEach(userEmail => {
                if (userEmail.userEmail === props.userMail && "sign" in userDoc) {
                    signedDate = userDoc.sign.signDate;
                }
            });
        });
        if (signedDate == null) {
            setSignDate(null);
        } else {
            setSignDate(Utils.getDateTimeFromJavaDate(signedDate));
        }
    }, [props.document.userDocuments, props.userMail]);

    const [signDate, setSignDate] = useState();
    const [downloadError, setDownloadError] = useState(null);

    const renderSign = () => {
        if (signDate != null) {
            if (signDate.includes("Ошибка")) {
                return <div className="text-danger">{signDate}</div>
            }
            return <div>{signDate}</div>;
        }
        return <button type="button" className="btn" onClick={signDocument}>Подписать</button>
    }

    const mobileElementClassName = () => {
        if (props.orderNumber===0 || Utils.isEven(props.orderNumber)) {
            return "grid d-lg-none text-left border-bottom pb-3 pt-3 pl-2 pr-2";
        }
        return "grid d-lg-none text-left border-bottom pb-3 pt-3 pl-2 pr-2 bg-light";
    }

    function download () {
        const url = serverHost + "/documents/isDocumentFileExists?documentId=" + props.document.documentId;
        Utils.jsonGet(url)
            .then((json) => {
                if (json.value) {
                    redirectToDownload();
                } else {
                    setDownloadError("Файл не найден");
                }
            }).catch((error) => {
                setDownloadError("Ошибка сервера");
            }); 
    }

    function redirectToDownload () {
        const url = serverHost + "/documents/getDocumentFileContents?documentId=" + props.document.documentId;
        const link = document.createElement("a");
        link.href = url;
        link.click();
    }

    const signDocument = () => {
        var mail = props.userMail;
        var id = props.document.documentId;
        var docSign = {
            "userMail": mail,
            "documentId": id
        }
        const url = serverHost + "/documents/signDocument";
        Utils.jsonPost(url, docSign)
            .then((result) => {
                setSignDate(Utils.getDateTimeFromJavaDate(result.value))
            })
            .catch((error) => {
                setSignDate("Ошибка на сервере при подписании документа!");
                console.log("error");
            });
    }
    const columnClassname = "col p-2";
    const doc = (
        <div>
            <div className="border-bottom row pt-1 pb-1 d-none d-lg-flex d-xl-flex">
                <div className = {columnClassname} style={{wordBreak: "break-word"}}>
                    {Utils.getDateTimeFromJavaDate(props.document.addDate)}
                </div>
                <div className = {columnClassname} style={{wordBreak: "break-word"}}>
                    {Utils.getDateFromJavaDate(props.document.documentDate)}
                </div>
                <div className = {columnClassname} style={{wordBreak: "break-word"}}>
                    {props.document.documentName}
                </div>
                <div className = {columnClassname} style={{wordBreak: "break-word"}}>
                    {props.document.documentNumber}
                </div>
                <div className  = {columnClassname} style={{wordBreak: "break-word"}}>
                    {renderSign()}
                </div>
                <div className = "col p-2"  style={{wordBreak: "break-all"}}>
                    {downloadError!=null && <div className = "text-danger">{downloadError}</div>}
                    <button className="btn" onClick={download}><i className="fa fa-download"></i></button>
                </div>
            </div>
            <div className={mobileElementClassName()}>
                <div style={{wordBreak: "break-word"}}> 
                    <b>Дата добавления - </b>{Utils.getDateTimeFromJavaDate(props.document.addDate)}
                </div>
                <div style={{wordBreak: "break-word"}}>
                    <b>Дата документа - </b>{Utils.getDateFromJavaDate(props.document.documentDate)}
                </div>
                    { signDate && <div><b>Дата подписания - </b>{signDate}</div>}
                <div style={{wordBreak: "break-word"}}>
                    <b>Название - </b>{props.document.documentName}
                </div>
                <div style={{wordBreak: "break-word"}}>
                    <b>Номер - </b>{props.document.documentNumber}
                </div>
                <div className = "mt-4" style={{wordBreak: "break-word"}}>
                    {downloadError!=null && <div className = "text-danger">{downloadError}</div>}
                    <button type="button" className="btn btn-lg  btn-block" onClick={download}>Открыть</button>
                </div>
                { !signDate && 
                    <div className = "mt-4" style={{wordBreak: "break-word"}}>
                        <button type="button" className="btn btn-lg  btn-block" onClick={signDocument}>Подписать</button>
                    </div>
                }
            </div>
        </div>
    );
    return (doc);

}

export default Document;