import React from 'react';
import * as Utils from "../src/utils";
import { serverHost } from "../src/config";

function LogoutButton(props) {

    function logout() {
        props.setUserMail(null);
        let url = serverHost + "/login/logout";
        Utils.jsonGet(url)
            .then(() =>
             console.log("logged out")
            )
            .catch((error) => {
             console.log(error);
        }); 
    }


    const logoutButton = (
        <div>
           <button onClick = {logout} className="btn">Выйти</button>
        </div>
    );


    return (logoutButton);



}

export default LogoutButton;