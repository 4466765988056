import React, { useState, useEffect } from 'react';
import * as Utils from "../src/utils";
import { serverHost } from "../src/config";


function DocumentFilterSelector(props) {

    const filterSelector = (
        <select className="ml-1" value={props.selectedFilter} onChange={e => props.setSelectedFilter(e.target.value)}>
            <option value="sign_date_asc">Дата подписания ↑</option>
            <option value="sign_date_desc">Дата подписания ↓</option>
            <option value="document_date_asc">Дата документа ↑</option>
            <option value="document_date_desc">Дата документа ↓</option>
        </select>

    );
    return filterSelector;



}

export default DocumentFilterSelector;