import React from 'react';
import { serverHost } from "../src/config";

function Auth() {

    function getRedirectUri() {
      if (serverHost.includes("localhost")) {
        return "https://localhost/dynamic/login/googleLogin";
      } 
      return "https://docs.balticom.lv/dynamic/login/googleLogin"
    }

    function getHref() {
        return "https://accounts.google.com/o/oauth2/v2/auth?" +
            "response_type=code&" +
            "client_id=194198900921-ehogp4flusc1lguqns452govd2hp12hq.apps.googleusercontent.com&" +
            "redirect_uri=" + getRedirectUri() + "&" +
            "scope=email&" +
            "state=xyzABC123&" +
            "prompt=consent";
    }


    const auth = (
        <div>
           <a className="btn btn-primary m-5" role="button" href={getHref()}>
            Войти
          </a>
        </div>
    );


    return (auth);



}

export default Auth;