import './App.css';
import DocumentsList from './DocumentsList';
import Auth from './Auth';
import React, { useState, useEffect } from 'react';
import * as Utils from "../src/utils";
import { serverHost } from "../src/config";
import LogoutButton from './LogoutButton';


function App() {
    useEffect(() => {
      setUserMail(null);
    }, []);

   // const handleUserMail = (newUserMail) =>{
   //   setUserMail(newUserMail);
  //  }

   // const [cookiesAllowed, setCookiesAllowed] = useState(true);

    // function checkThirdPartyCookies() {
    //   var receiveMessage = function (evt) {
    //     if (evt.data === 'MM:3PCunsupported') {
    //       console.log("third party unsup");
    //       return false;
    //     } else if (evt.data === 'MM:3PCsupported') {
    //       console.log("third party sup");
    //       return true;
    //     }
    //     console.log("third party unsup")
    //     return false;
    //   };
    //   console.log(receiveMessage);
    //   return receiveMessage;
    // }

    function checkLoggedIn() {
      let url = serverHost + "/login/checkLoggedIn";
      Utils.jsonGet(url)
        .then((json) =>
          setUserMail(json.email)
        )
        .catch((error) => {
          console.log(error);
      });
    }

    const renderMainPage = () => {
      redirectIfNeeded();
      // if (!cookiesAllowed) {
      //   return <div>Включите Cookie файлы!<br></br>Чтобы использовать это приложение, в браузере должно быть включены Cookie файлы</div>;
      // }
      
      checkLoggedIn();

      if (userMail!=null) {
        return  <div>
                  {renderNavigationbar()}<DocumentsList userMail = {userMail}/>
                </div>;
      }
      var urlString =  window.location.href;
      var url = new URL(urlString);
      var c = url.searchParams.get("err");
      var errMessage = "";
      if (c!=null) {
        if (c==="notInDb") {
          errMessage = "Ошибка: ваша почта не найдена в базе данных";
        } else {
          errMessage = "Ошибка аутентификации Google";
        }
      }
      //return <Login loginCallback = {handleUserMail} setCookiesAllowed = {setCookiesAllowed}/>;
      return <div>{errMessage!=="" && <div className = "bg-danger text-white">{errMessage}</div>}<Auth/></div>;
    }

    function redirectIfNeeded() {
      if (window.location.protocol !== 'https:') {
        window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
      }
    }

    const renderNavigationbar = () => {
      return  <div>
                <div className = "d-none d-lg-inline d-xl-inline">
                    <nav className = "navbar navbar-expand-lg navbar-light bg-light">
                    <ul className = "navbar-nav">
                      <li className="nav-item p-2">Документы {userMail}</li> 
                    </ul>
                    <ul className="navbar-nav ml-auto d-block">
                      <li className="nav-item"><LogoutButton setUserMail = {setUserMail}/></li>
                    </ul>
                    </nav>
                </div>
                <div className = "d-lg-none">
                    <nav className = "navbar navbar-light bg-light">
                      <div className = "mb-2">
                        Документы {userMail}
                      </div>
                      <div className = "mb-3">
                        <LogoutButton setUserMail = {setUserMail}/>
                      </div>
                    </nav>
                </div>
              </div>
    }

    const [userMail, setUserMail] = useState();
    return (
      <div className="App">
        { renderMainPage() }
      </div>
    )
}

export default App;
